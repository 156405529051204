@import "./variables_black";

[data-bs-theme="black"] {
  color-scheme: black;
  // scss-docs-start root-black-mode-vars
  --#{$prefix}primary: #{#ffffff};
  --#{$prefix}secondary: #{#919190};
  --#{$prefix}primary-rgb: #{to-rgb(#ffffff)};
  --#{$prefix}secondary-rgb: #{to-rgb(#919190)};

  --#{$prefix}body-color: #{#919190};
  --#{$prefix}body-color-rgb: #{to-rgb(#919190)};

  --#{$prefix}link-color: #{#919190};
  --#{$prefix}link-color-rgb: #{to-rgb(#919190)};

  --#{$prefix}nav-pills-link-active-bg: #{#4b4b4b};
  --#{$prefix}nav-pills-link-active-color: #{#313131};

  --#{$prefix}modal-border-color: #{#141414};

  --#{$prefix}emphasis-color: #{$body-emphasis-color-dark};
  --#{$prefix}emphasis-color-rgb: #{to-rgb($body-emphasis-color-dark)};

  --#{$prefix}secondary-color: #{$body-secondary-color-dark};
  --#{$prefix}secondary-color-rgb: #{to-rgb($body-secondary-color-dark)};
  --#{$prefix}secondary-bg: #{$body-secondary-bg-dark};
  --#{$prefix}secondary-bg-rgb: #{to-rgb($body-secondary-bg-dark)};

  --#{$prefix}tertiary-color: #{$body-tertiary-color-dark};
  --#{$prefix}tertiary-color-rgb: #{to-rgb($body-tertiary-color-dark)};
  --#{$prefix}tertiary-bg: #313131;
  --#{$prefix}tertiary-bg-rgb: #{to-rgb(#313131)};

  --bs-info: #313131;
  --bs-btn-color: #ffffff !important;

  @each $color, $value in $theme-colors-text-dark {
    --#{$prefix}#{$color}-text-emphasis: #{$value};
  }

  @each $color, $value in $theme-colors-bg-subtle-dark {
    --#{$prefix}#{$color}-bg-subtle: #{$value};
  }

  @each $color, $value in $theme-colors-border-subtle-dark {
    --#{$prefix}#{$color}-border-subtle: #{$value};
  }

  --#{$prefix}heading-color: #{$headings-color-dark};

  --#{$prefix}link-hover-color: #{$link-hover-color-dark};
  --#{$prefix}link-hover-color-rgb: #{to-rgb($link-hover-color-dark)};

  --#{$prefix}code-color: #{$code-color-dark};
  --#{$prefix}highlight-color: #{$mark-color-dark};
  --#{$prefix}highlight-bg: #{$mark-bg-dark};

  --#{$prefix}border-color: #{$border-color-dark};
  --#{$prefix}border-color-translucent: #{$border-color-translucent-dark};

  --#{$prefix}form-valid-color: #{$form-valid-color-dark};
  --#{$prefix}form-valid-border-color: #{$form-valid-border-color-dark};
  --#{$prefix}form-invalid-color: #{$form-invalid-color-dark};
  --#{$prefix}form-invalid-border-color: #{$form-invalid-border-color-dark};

  $header-bg: #191716;

  --#{$prefix}card-height: 60px;


  // Navigation

  .card.card-body ul.nav a.nav-link {
    height: 72px;
  }

  .card.card-body .black-align-text {
    position: relative;
    top: 15px;
  }

  // Buttons

  .btn-outline-primary {
    border: none;
    color: #ffffff;
    background-color: #4b4b4b;
  }

  .btn-outline-primary:hover {
    border: none;
    color: #ffffff;
    background-color: #919190;
  }

  .btn-outline-secondary {
    --bs-btn-bg: #313131;
    --bs-btn-color: #ffffff;
    --bs-btn-border-color: #313131;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #4b4b4b;
    --bs-btn-hover-border-color: #4b4b4b;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #4b4b4b;
    --bs-btn-active-border-color: #4b4b4b;
    --bs-btn-disabled-color: #313131;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #313131;
    --bs-gradient: none;
  }

  .btn-danger {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #ba3e2c;
    --bs-btn-border-color: #ba3e2c;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #ba3e2c;
    --bs-btn-hover-border-color: #ba3e2c;
    --bs-btn-focus-shadow-rgb: #ffffff00;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #ba3e2c;
    --bs-btn-active-border-color: #ba3e2c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    --bs-btn-disabled-color: #ba3e2c;
    --bs-btn-disabled-bg: rgba(255, 255, 255, 0);
    --bs-btn-disabled-border-color: #ba3e2c;
  }

  // switch buttons

  #formPin,
  #formBlocked,
  #formChangePassword,
  #formOfficeFunction {
    //background-color: #ffffff00 !important;
    border: 0;
  }

  .switch-new-design-black-flex {
    flex-flow: row;
  }

  .switch-new-design-black-flex h6 {
    color: #ffffff;
  }

  .switch-new-design-black-flex-row {
    width: 10rem;
  }

  .switch-new-design-black label {
    top: 12px;
    font-size: 16px;
    //color: #ffffff !important;
    //left: 0;
  }

  .switch-new-design-black #formBlocked .form-switch {
    left: 120px !important;
  }

  .switch-new-design-black.switch-new-design-black-flex .form-switch {
    top: 0 !important;
  }

  .switch-new-design-black.switch-new-design-black-flex label {
    margin-left: 60px;
  }

  .switch-new-design-black #formChangePassword .form-switch {
    left: 200px !important;
  }

  .switch-new-design-black #formOfficeFunction .form-switch,
  .switch-new-design-black #formBeeper .form-switch {
    left: 56px !important;
  }

  .switch-new-design-black .form-switch {
    left: 45px;
    max-width: fit-content;
    top: -10px;
    background-color: #ffffff00;
    border: 0;
  }

  .switch-new-design-black .form-switch input {
    scale: 1.2;
    //border: 0;
  }

  .switch-new-design-black #officeFunction {
    margin-left: 2px;
  }

  // General

  #layout-wrapper {
    background-image: linear-gradient(to bottom, #292827, #1c1c1c);
  }

  h1, h2, h3, h4, h5 {
    font-family: Magistral;
  }

  select {
    box-shadow: none !important;
  }

  option:hover {
    background-color: #4b4b4b !important;
  }

  .container:not(.black-unset-max-width) {
    max-width: 100%;
  }

  .card-body:not(.black-padding-reset),
  app-item-list .card.p-4 .card-body .card-body,
  .card.p-4,
  #itemEditHeader {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  app-item-list .card.p-4 .card-body,
  app-item-list .card.p-4 #itemEditHeader {
    padding: var(--bs-card-spacer-y) calc(var(--bs-card-spacer-x) / 2) !important;
  }

  app-access-profile-form .switch-new-design-black .form-floating-icon {
    height: 70%;
  }

  .list-group-item-action {
    border-radius: 6px;
  }

  .modal-body .list-group .list-group-item.list-group-item-action {
    border: 0;
  }

  .list-group-item.list-group-item-action {
    background-color: #313131;
    color: #ffffff;
    margin-bottom: 3vh;
    border-bottom: 0;
  }

  .list-group-item.list-group-item-action div,
  .list-group-item.list-group-item-action .item-selector {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .black-margin-height-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .black-flex-middle {
    margin: auto;
  }

  .black-flex-end {
    display: flex;
    align-items: center;;
  }

  // Sticky Select All

  .card-header.black-multi-select {
    background-color: #313131 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  // Login Specifications

  .black-new-account,
  .black-footer-ces {
    color: #ffffff;
  }

  .black-password-forgotten a,
  .black-password-forgotten {
    color: #919190;
  }

  .mb-3 {
    margin-bottom: 3vh !important;
  }

  .invalid-feedback {
    display: block;
    position: absolute;
  }

  .btn-outline-light:hover {
    color: #313131 !important;
  }

  .background-helper {
    background-image: linear-gradient(to bottom, #292827, #1c1c1c) !important;
  }

  .form-floating-custom > .form-control,
  .form-floating-custom > .form-select,
  .form-control input:-webkit-autofill,
  .form-control {
    background-color: #313131;
    border-color: #313131;
    color: #ffffff;
  }

  .form-control.is-invalid {
    border-color: #ba3e2c !important;
  }

  .is-valid {
    color: #00A094 !important;
  }


  .form-floating > .form-control:focus ~ label:after {
    background-color: rgba(255, 255, 255, 0);
  }

  .form-control input {
    background-color: #313131;
  }

  .logo img,
  .auth-logo img,
  #sidebar-menu ul li a .nav-icon,
  .header-item .logo img,
  .ces-locking-device,
  .user-image img,
  .fa, .fas,
  .form-floating-icon i,
  .list-group .list-group-item .avatar img,
  .black-access-profile-time-modal-form .avatar img,
  app-access-profilesapp-access-profiles .avatar img,
  app-locking-devices .avatar img,
  app-time-models .avatar img,
  app-jobs .avatar img,
  app-locking-media .avatar img,
  app-access-profiles .avatar img,
  app-locking-device-devices .avatar img,
  .accordion-button:not(.collapsed)::after,
  .accordion-button::after {
    filter: grayscale(100%) brightness(200);
  }

  #sidebar-menu ul li a .nav-icon {
    transition: unset;
  }

  // Header Configurations
  // Navigation Configuration

  .p-3.bg-primary.border-bottom {
    background-color: #141414 !important;
  }

  .navbar-brand-box,
  #page-topbar {
    background-color: #191716;
    box-shadow: -2px 3px 3px 0 rgba(52, 58, 64, 0.06);
  }


  .sidebar-menu-scroll {
    background-color: #141414;
  }

  .user .user-name,
  .user .user-sub-title,
  .alertify .ajs-body,
  #sidebar-menu .metismenu .menu-item {
    color: #ffffff;
  }

  .list-group-item,
  #sidebar-menu ul li.mm-active > a {
    background-color: #313131;
  }

  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label,
  .logo img {
    background-color: #ffffff00;
  }

  .vertical-menu {
    border-right-color: #141414;
  }

  #sidebar-menu ul li a:hover .nav-icon {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  .vertical-menu #sidebar-menu > ul > li:hover > a,
  #sidebar-menu ul li a:hover,
  .header-item:hover {
    background-color: #313131 !important;
  }

  //.bg-primary,
  .dropdown-menu,
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #141414 !important;
    color: #ffffff !important;
  }

  .dropdown-menu-end {
    background-color: #141414 !important;
    color: #919190 !important;
    border: 0;
  }

  .dropdown-divider {
    border-top-color: #626160;
  }

  // Mdal

  .modal-body {
    min-height: 500px !important;
  }

  .modal-content {
    background-color: #141414;
    border-color: #141414;
  }

  .modal-title {
    color: #ffffff;
  }

  .alertify .ajs-dialog,
  .alertify .ajs-dialog .ajs-header,
  .alertify .ajs-dialog .ajs-footer {
    background-color: #141414;
    color: #ffffff;
  }

  // License

  .form-control .progress .text-bg-primary {
    color: #0087cc !important;
    background-color: #0087cc !important;
  }

  app-system .col-lg-6 {
    margin-top: 0;
    @media (min-width: 992px) {
      height: fit-content !important;
    }
  }

  app-system #itemEdit > div > div.card-body > form > div > div:nth-child(3) {
    @media (min-width: 992px) {
      margin-top: -265px;
    }
  }

  app-locking-media app-item-list .card.p-4 .card-header,
  app-system app-item-list .card.p-4 .card-header {
    //padding-top: calc(var(--bs-card-spacer-y) * 2) !important;
    padding-bottom: 0;
  }

  // Main Content

  .main-content {
    height: 100%;
    min-height: 100vh;
    background-image: url("../ces/logo/black-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 0;
    background-position-x: 5vw;
    background-attachment: fixed;
  }

  .main-background {
    height: 100%;
    background-image: url("../ces/logo/black-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .page-content {
    backdrop-filter: grayscale(100%);
  }

  .text-dark,
  .text-black,
  .page-title-box h3,
  .nav-tabs-custom .nav-item .nav-link.active {
    color: #ffffff !important;
  }

  .black-no-border {
    border: 0 !important;
  }

  .card,
  .card-header {
    color: #ffffff;
    background-color: #ffffff00 !important;
    border: 0;
    box-shadow: unset;
    min-height: 53px;
  }

  // Users

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #ffffff;
    background-color: #4b4b4b;
  }

  .bg-light {
    background-color: #313131 !important;
  }

  .nav-tabs > li > a, .nav-pills > li > a {
    color: #FFFFFF;
  }

  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: #313131;
  }

  .list-group-item-action {
    background-color: #141414;
  }

  // Zutrittsprofile

  app-access-profile-form .container .card.card-body {
    @media (min-width: 992px) {
      flex-flow: row;
      flex-wrap: wrap;
    }
  }

  app-access-profile-form .container .card.card-body .form-floating {
    @media (min-width: 992px) {
      width: 49%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }
  }

  // Schließgeräte

  #deviceParamsGroupWarning .card {
    background-color: #919190 !important;
  }

  .d-flex .gap-2,
  div.d-flex.h-100.card-body > div {
    width: 100%;
  }

  .d-flex .gap-2 h5 {
    flex-grow: 1;
  }

  app-user-groups app-user-group-assignment-form .list-group .list-group-item .avatar img,
  .black-profile-filter-unset img,
  img#item-overlay,
  img#itemOverlay,
  .ces-locking-device.battery {
    filter: unset !important;
  }

  .badge-soft-primary {
    background-color: #707070;
  }

  .blocked-user-badge {
    font-size: 20px;
    color: #9d1400;
  }

  .card-body .nav-tabs-custom {
    border-bottom: unset;
    background-color: #313131;
  }

  .nav-link.active {
    color: #ffffff;
    background-color: #4b4b4b;
  }

  .form-floating-custom .form-floating-icon {
    color: #ffffff;
  }

  .form-floating > .form-control:focus ~ label:after,
  .form-floating > .form-control:not(:placeholder-shown) ~ label:after,
  .form-floating > .form-control-plaintext ~ label:after,
  .form-floating > .form-select ~ label:after,
  .nav-tabs-custom .nav-item .nav-link:after {
    background: #ffffff00;
  }

  .form-switch,
  .form-check-input,
  .form-check-input:checked {
    background-color: #313131;
    border-color: #ffffff;
  }

  .form-switch .form-check-input,
  .form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
  }

  .form-switch .form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300a094'/%3e%3c/svg%3e")
  }

  .black-font {
    font-family: 'Magistral';
  }

  .accordion {
    --bs-accordion-border-color: rgba(255, 255, 255, 0);
    --bs-accordion-active-color: #ffffff;
    --bs-accordion-active-bg: #4b4b4b;
  }

  .btn-outline-primary {
    --bs-btn-color: #0087cc;
    --bs-btn-border-color: #0087cc;
    --bs-btn-hover-bg: #0087cc;
    --bs-btn-hover-border-color: #0087cc;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0087cc;
    --bs-btn-active-border-color: #0087cc;
    --bs-btn-disabled-color: #0087cc;
    --bs-btn-disabled-border-color: #0087cc;
  }

  app-time-model-list-of-days-form form div.list-group div.list-group-item.text-center {
    background-color: #919190;
    border: 0;
  }

  app-time-model-list-of-days-form form div.list-group div.list-group-item.d-flex {
    background-color: #313131;
    border: 0;
    color: #ffffff;
  }


  //Hide dorpdown when grous are not visable and border 0
  //Hide navbars if there is only one element to be selected

  .accordion.accordion-body:has(> div.accordion-item:last-child:nth-child(1)) > .accordion-item > .accordion-header,
  div.card.card-body.mb-2:has(ul):has(li:last-child:nth-child(1)),
  .card ul.nav.nav-pills:has(> li:last-child:nth-child(1)) {
    display: none;
  }

  .accordion.accordion-body:has(> div.accordion-item:last-child:nth-child(1)) > .accordion-item {
    border: 0;
  }

  //Lighten up the arrows on the select menu

  .form-select {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }

  .form-select::after {
    transform: rotate(180deg);
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"));
  }

  // Custom arrows on number Input

  .form-numberInput {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &.is-invalid + div {
      right: 37px;
    }

    & + div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      height: 100%;
      top: 0;
      right: 13px;

      & > .buttons {
        height: 15px;
        display: flex;
        align-items: center;
      }
    }
  }


  // Reverting invalid feedback position on specific element

  app-interval-selector .invalid-feedback {
    position: unset;
  }

  //icons

  app-access-profile-form .switch-new-design-black i {
    margin-top: 15px;
  }

  // Search bar
  // Dropdown

  .dropdown-item {
    color: #ffffff;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    background-color: #141414;
  }

  .dropdown-item:hover {
    color: #ffffff;
    background-color: #313131 !important;
  }

  // Open Source License
  #open-source-content table, #open-source-content th, #open-source-content td {
    border: 1px solid #141414;
    border-collapse: collapse;
  }

  app-legal div.container {
    background-image: linear-gradient(to bottom, #292827, #1c1c1c) !important;
    width: 100%;
    max-width: unset;
    overflow: hidden;
  }

  div:has(> app-password-complexity):has(> p) > div {
    min-height: 120px;
  }

  // PDF Download

  app-logbook app-pagetitle {
    max-height: 72px !important;
  }

  // NGB Date Picker
  .ngb-dp-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 36px !important;
    border-bottom-color: #141414 !important;
    color: #919190 !important;
  }

  .ngb-dp-week.ngb-dp-weekdays.ng-star-inserted,
  .ngb-dp-navigation-select .form-select {
    background-color: #313131;
    color: #ffffff;
    border-color: #313131;
  }

  .ngb-dp-navigation-chevron {
    color: #ffffff !important;
  }

  .ngb-dp-header {
    min-width: 300px;
  }

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-content.ngb-dp-months {
    z-index: 900;
  }

  .ngb-dp-month .ng-star-inserted,
  .ngb-db.content .ng-star-inserted {
    flex-grow: 1;
    color: #919190;
    padding: 2px;
  }

  .btn-light .ng-star-inserted {
    color: #919190 !important;
  }

  .btn-light .bg-primary .text-white .ng-star-inserted {
    background-color: rgba(0, 160, 148, 0.62) !important;
  }

  ngb-datepicker {
    border: 1px #1c1c1c !important;
  }

  ngb-datepicker.dropdown-menu.show.ng-star-inserted {
    top: unset !important;
  }

  .btn-light.bg-primary.text-white.ng-star-inserted {
    background-color: #919190 !important;
  }

  div.btn-light.bg-primary.text-white {
    background-color: #495057 !important;
    border-color: rgba(0, 160, 148, 0.45);
  }

  // Auto Complete

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px rgb(49, 49, 49) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  // Changes and Validation Feedback

  .form-control {
    border-color: #313131 !important;
  }

  .invalid-feedback-ngd {
    background-clip: padding-box !important;
    border: 1px solid #ba3e2c !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .pending-changes {
    background-clip: padding-box !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
